export const FEATURES = {
  OPS_FOOTER_GOOGLE_REVIEWS: 'ops_footer_google_reviews',
  RELEASE_BUYER_APPLICATION_AUTH_REMOVAL:
    'release_buyer_application_auth_removal',
  REL_BREEDER_SHARE_PAYMENT_INFO: 'rel_breeder_share_payment_info',
  REL_REQUIRED_PUPPY_PHOTO: 'rel_required_puppy_photo',
  REL_BNPL_TOGGLE: 'rel_bnpl_toggle',
  REL_DROP_OFF_POINTS: 'rel_drop_off_points',
  REL_HOMEPAGE: 'rel_homepage'
};
